.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}
#select-input-download-file {
    width: 60%;
    min-width: 100px;
}
#select-label-download-file {
    display: flex;
    justify-content: center;
}